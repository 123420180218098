import React from "react";

import ContactForm from "../../../../ContactForm";

const TankTechnik = ({ name = "tanktechnik-knallerpreise" }) => {
    return (
        <ContactForm
            phoneHeadline="0 42 62 - 79 94 22"
            phoneLink="+494262799422"
            emailHeadline="technik.online@hoyer.de"
            urlEnding="tanktechnik-knallerpreise"
            canSendStorageString={`${name}::contact-form:sent-at`}
            pushToGTMString={`${name}:contact-form`}
        />
    );
};

export default TankTechnik;
